export { default as ProfileUserIcon } from '@assets/icons/profile/user-icon.svg';
export { default as EditPencilIcon } from '@assets/icons/profile/edit-pencil.svg';
export { default as CopyIcon } from '@assets/icons/profile/copy-icon.svg';
export { default as BasketIcon } from '@assets/icons/profile/basket.svg';
export { default as HandMoneyYellowIcon } from '@assets/icons/profile/hand-money-yellow.svg';
export { default as SafeYellowIcon } from '@assets/icons/profile/safe-yellow.svg';
export { default as WrenchIcon } from '@assets/icons/profile/wrench-icon.svg';
export { default as GoldIcon } from '@assets/icons/profile/gold-coin-icon.svg';
export { default as FileRefreshIcon } from '@assets/icons/profile/file-refresh-icon.svg';

export { default as SadFaceIcon } from '@assets/icons/profile/modal/sad-face-icon.svg';
export { default as SwapIcon } from '@assets/icons/profile/modal/swap-icon.svg';
export { default as WarningIcon } from '@assets/icons/profile/modal/warning-icon.svg';

export { default as TransferIcon } from '@assets/icons/profile/transfer-icon.svg';
export { default as ArrowUpIcon } from '@assets/icons/profile/arrow-up-icon.svg';
export { default as ArrowDownIcon } from '@assets/icons/profile/arrow-down-icon.svg';
export { default as EmptyBoxIcon } from '@assets/icons/profile/empty-box-icon.svg';
